<bit-section *ngIf="hasCustomFields">
  <bit-section-header>
    <h2 bitTypography="h6">{{ "customFields" | i18n }}</h2>
  </bit-section-header>
  <form [formGroup]="customFieldsForm">
    <bit-card
      formArrayName="fields"
      cdkDropList
      (cdkDropListDropped)="drop($event)"
      data-testid="custom-fields"
    >
      <div
        *ngFor="let field of fields.controls; let i = index"
        [formGroupName]="i"
        class="tw-flex tw-p-3 -tw-mx-3 tw-gap-4 tw-bg-background tw-rounded-lg first:-tw-mt-3 last-of-type:tw-mb-0"
        [ngClass]="{
          'tw-items-center': field.value.type === FieldType.Boolean,
        }"
        [attr.data-testid]="field.value.name + '-entry'"
        cdkDrag
        #customFieldRow
      >
        <!-- Text Field -->
        <bit-form-field *ngIf="field.value.type === FieldType.Text" class="tw-flex-1" disableMargin>
          <bit-label>{{ field.value.name }}</bit-label>
          <input bitInput formControlName="value" data-testid="custom-text-field" />
        </bit-form-field>

        <!-- Hidden Field -->
        <bit-form-field
          *ngIf="field.value.type === FieldType.Hidden"
          class="tw-flex-1"
          disableMargin
        >
          <bit-label>{{ field.value.name }}</bit-label>
          <input
            bitInput
            formControlName="value"
            type="password"
            data-testid="custom-hidden-field"
            class="tw-font-mono"
          />
          <button
            type="button"
            bitIconButton
            bitSuffix
            bitPasswordInputToggle
            data-testid="visibility-for-custom-hidden-field"
            *ngIf="canViewPasswords(i)"
            (toggledChange)="logHiddenEvent($event)"
          ></button>
        </bit-form-field>

        <!-- Boolean Field -->
        <bit-form-control
          *ngIf="field.value.type === FieldType.Boolean"
          class="tw-flex-1"
          disableMargin
        >
          <input
            bitCheckbox
            formControlName="value"
            type="checkbox"
            data-testid="custom-boolean-field"
          />
          <bit-label>{{ field.value.name }}</bit-label>
        </bit-form-control>

        <!-- Linked Field -->
        <bit-form-field
          *ngIf="field.value.type === FieldType.Linked"
          class="tw-flex-1"
          disableMargin
        >
          <bit-label>{{ field.value.name }}</bit-label>
          <bit-select formControlName="linkedId" data-testid="custom-linked-field">
            <bit-option
              *ngFor="let option of linkedFieldOptions"
              [value]="option.value"
              [label]="option.name"
            ></bit-option>
          </bit-select>
        </bit-form-field>

        <button
          type="button"
          (click)="openAddEditCustomFieldDialog({ index: i, label: field.value.name })"
          [appA11yTitle]="'editFieldLabel' | i18n: field.value.name"
          bitIconButton="bwi-pencil-square"
          class="tw-self-end"
          data-testid="edit-custom-field-button"
          *ngIf="!isPartialEdit"
        ></button>

        <button
          type="button"
          bitIconButton="bwi-hamburger"
          class="tw-self-end"
          cdkDragHandle
          [appA11yTitle]="'reorderToggleButton' | i18n: field.value.name"
          (keydown)="handleKeyDown($event, field.value.name, i)"
          data-testid="reorder-toggle-button"
          *ngIf="!isPartialEdit"
        ></button>
      </div>

      <button
        type="button"
        bitLink
        linkType="primary"
        (click)="openAddEditCustomFieldDialog()"
        *ngIf="!isPartialEdit"
      >
        <i class="bwi bwi-plus tw-font-bold" aria-hidden="true"></i>
        {{ "addField" | i18n }}
      </button>
    </bit-card>
  </form>
</bit-section>
