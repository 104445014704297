<bit-dialog>
  <span bitDialogTitle>{{ applicationName }}</span>
  <ng-container bitDialogContent>
    <div class="tw-flex tw-flex-col tw-gap-2">
      <span bitDialogTitle>{{ "atRiskMembersWithCount" | i18n: members.length }} </span>
      <span>{{ "atRiskMembersDescriptionWithApp" | i18n: applicationName }}</span>
      <div class="tw-mt-1">
        <ng-container *ngFor="let member of members">
          <div>{{ member.email }}</div>
        </ng-container>
      </div>
    </div>
  </ng-container>
  <ng-container bitDialogFooter>
    <button bitButton bitDialogClose buttonType="secondary" type="button">
      {{ "close" | i18n }}
    </button>
  </ng-container>
</bit-dialog>
