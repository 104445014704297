<bit-container>
  <p>{{ "passwordsReportDesc" | i18n }}</p>
  <div *ngIf="loading">
    <i
      class="bwi bwi-spinner bwi-spin tw-text-muted"
      title="{{ 'loading' | i18n }}"
      aria-hidden="true"
    ></i>
    <span class="tw-sr-only">{{ "loading" | i18n }}</span>
  </div>
  <div class="tw-mt-4" *ngIf="!loading && dataSource.data.length">
    <bit-table [dataSource]="dataSource">
      <ng-container header>
        <tr bitRow>
          <th bitCell></th>
          <th bitCell bitSortable="name">{{ "name" | i18n }}</th>
          <th bitCell class="tw-text-right">{{ "weakness" | i18n }}</th>
          <th bitCell class="tw-text-right">{{ "timesReused" | i18n }}</th>
          <th bitCell class="tw-text-right">{{ "timesExposed" | i18n }}</th>
        </tr>
      </ng-container>
      <ng-template body let-rows$>
        <tr bitRow *ngFor="let r of rows$ | async">
          <td bitCell>
            <app-vault-icon [cipher]="r"></app-vault-icon>
          </td>
          <td bitCell>
            <ng-container>
              <span>{{ r.name }}</span>
            </ng-container>
            <br />
            <small>{{ r.subTitle }}</small>
          </td>
          <td bitCell class="tw-text-right">
            <span
              bitBadge
              *ngIf="r.weakPasswordDetail"
              [variant]="r.weakPasswordDetail?.detailValue.badgeVariant"
            >
              {{ r.weakPasswordDetail?.detailValue.label | i18n }}
            </span>
          </td>
          <td bitCell class="tw-text-right">
            <span bitBadge *ngIf="passwordUseMap.has(r.login.password)" variant="warning">
              {{ "reusedXTimes" | i18n: passwordUseMap.get(r.login.password) }}
            </span>
          </td>
          <td bitCell class="tw-text-right">
            <span bitBadge variant="warning" *ngIf="r.exposedPasswordDetail">
              {{ "exposedXTimes" | i18n: r.exposedPasswordDetail?.exposedXTimes }}
            </span>
          </td>
        </tr>
      </ng-template>
    </bit-table>
  </div>
</bit-container>
