<bit-dialog #dialog background="alt">
  <span bitDialogTitle>{{ "generatorHistory" | i18n }}</span>
  <ng-container bitDialogContent>
    <bit-empty-credential-history *ngIf="!(hasHistory$ | async)" style="display: contents" />
    <bit-credential-generator-history *ngIf="hasHistory$ | async" />
  </ng-container>
  <ng-container bitDialogFooter>
    <button
      [disabled]="!(hasHistory$ | async)"
      bitButton
      type="submit"
      buttonType="primary"
      (click)="clear()"
    >
      {{ "clearHistory" | i18n }}
    </button>
    <!-- FIXME: Remove the close button once the dialog doesn't overlap electron's
         drag area.
     -->
    <button bitButton type="submit" buttonType="secondary" (click)="close()">
      {{ "close" | i18n }}
    </button>
  </ng-container>
</bit-dialog>
