<div
  class="tw-fixed tw-z-50 tw-w-full tw-flex tw-justify-center tw-opacity-0 focus-within:tw-opacity-100 tw-pointer-events-none focus-within:tw-pointer-events-auto"
>
  <nav class="tw-bg-background-alt3 tw-rounded-md tw-rounded-t-none tw-py-2 tw-text-alt2">
    <a
      bitLink
      class="tw-mx-6 focus-visible:before:!tw-ring-0"
      [fragment]="mainContentId"
      [routerLink]="[]"
      (click)="focusMainContent()"
      linkType="light"
      >{{ "skipToContent" | i18n }}</a
    >
  </nav>
</div>
<div class="tw-flex tw-w-full">
  <ng-content select="bit-side-nav, [slot=side-nav]"></ng-content>
  <main
    [id]="mainContentId"
    tabindex="-1"
    class="tw-overflow-auto tw-min-w-0 tw-flex-1 tw-bg-background tw-p-6 md:tw-ml-0 tw-ml-16"
  >
    <ng-content></ng-content>

    <!-- overlay backdrop for side-nav -->
    <div
      *ngIf="{
        open: sideNavService.open$ | async,
      } as data"
      class="tw-pointer-events-none tw-fixed tw-inset-0 tw-z-10 tw-bg-black tw-bg-opacity-0 motion-safe:tw-transition-colors md:tw-hidden"
      [ngClass]="[data.open ? 'tw-bg-opacity-30 md:tw-bg-opacity-0' : 'tw-bg-opacity-0']"
    >
      <div
        *ngIf="data.open"
        (click)="sideNavService.toggle()"
        class="tw-pointer-events-auto tw-h-full tw-w-full"
      ></div>
    </div>
  </main>
  <ng-template [cdkPortalOutlet]="drawerPortal()"></ng-template>
</div>
