<bit-container>
  <p>{{ "passwordsReportDesc" | i18n }}</p>
  <div *ngIf="loading">
    <i
      class="bwi bwi-spinner bwi-spin tw-text-muted"
      title="{{ 'loading' | i18n }}"
      aria-hidden="true"
    ></i>
    <span class="tw-sr-only">{{ "loading" | i18n }}</span>
  </div>
  <div class="tw-mt-4" *ngIf="!loading">
    <bit-table [dataSource]="dataSource">
      <ng-container header>
        <tr bitRow>
          <th bitCell bitSortable="hostURI">{{ "application" | i18n }}</th>
          <th bitCell class="tw-text-right">{{ "weakness" | i18n }}</th>
          <th bitCell class="tw-text-right">{{ "timesReused" | i18n }}</th>
          <th bitCell class="tw-text-right">{{ "timesExposed" | i18n }}</th>
          <th bitCell class="tw-text-right">{{ "totalMembers" | i18n }}</th>
        </tr>
      </ng-container>
      <ng-template body let-rows$>
        <tr bitRow *ngFor="let r of rows$ | async">
          <td bitCell>
            <ng-container>
              <span>{{ r.hostURI }}</span>
            </ng-container>
          </td>
          <td bitCell class="tw-text-right">
            <span
              bitBadge
              *ngIf="passwordStrengthMap.has(r.id)"
              [variant]="passwordStrengthMap.get(r.id)[1]"
            >
              {{ passwordStrengthMap.get(r.id)[0] | i18n }}
            </span>
          </td>
          <td bitCell class="tw-text-right">
            <span bitBadge *ngIf="passwordUseMap.has(r.login.password)" variant="warning">
              {{ "reusedXTimes" | i18n: passwordUseMap.get(r.login.password) }}
            </span>
          </td>
          <td bitCell class="tw-text-right">
            <span bitBadge *ngIf="exposedPasswordMap.has(r.id)" variant="warning">
              {{ "exposedXTimes" | i18n: exposedPasswordMap.get(r.id) }}
            </span>
          </td>
          <td bitCell class="tw-text-right" data-testid="total-membership">
            {{ totalMembersMap.get(r.id) || 0 }}
          </td>
        </tr>
      </ng-template>
    </bit-table>
  </div>
</bit-container>
