<!-- FIXME: root$ should be powered using a reactive form -->
<bit-toggle-group
  fullWidth
  class="tw-mb-4"
  [selected]="(root$ | async).nav"
  (selectedChange)="onRootChanged({ nav: $event })"
  attr.aria-label="{{ 'type' | i18n }}"
>
  <bit-toggle *ngFor="let option of rootOptions$ | async" [value]="option.value">
    {{ option.label }}
  </bit-toggle>
</bit-toggle-group>

<bit-card class="tw-flex tw-justify-between tw-mb-4">
  <div class="tw-grow tw-flex tw-items-center">
    <bit-color-password class="tw-font-mono" [password]="value$ | async"></bit-color-password>
  </div>
  <div class="tw-flex tw-items-center tw-space-x-1">
    <button
      type="button"
      bitIconButton="bwi-generate"
      buttonType="main"
      (click)="generate(USER_REQUEST)"
      [appA11yTitle]="credentialTypeGenerateLabel$ | async"
      [disabled]="!(algorithm$ | async)"
    >
      {{ credentialTypeGenerateLabel$ | async }}
    </button>
    <button
      type="button"
      bitIconButton="bwi-clone"
      buttonType="main"
      showToast
      [appA11yTitle]="credentialTypeCopyLabel$ | async"
      [appCopyClick]="value$ | async"
      [valueLabel]="credentialTypeLabel$ | async"
      [disabled]="!(algorithm$ | async)"
    ></button>
  </div>
</bit-card>
<tools-password-settings
  class="tw-mt-6"
  *ngIf="(showAlgorithm$ | async)?.id === 'password'"
  [userId]="userId$ | async"
  (onUpdated)="generate('password settings')"
/>
<tools-passphrase-settings
  class="tw-mt-6"
  *ngIf="(showAlgorithm$ | async)?.id === 'passphrase'"
  [userId]="userId$ | async"
  (onUpdated)="generate('passphrase settings')"
/>
<bit-section *ngIf="(category$ | async) !== 'password'">
  <bit-section-header>
    <h2 bitTypography="h6">{{ "options" | i18n }}</h2>
  </bit-section-header>
  <div class="tw-mb-4">
    <bit-card>
      <form [formGroup]="username" class="tw-container">
        <bit-form-field>
          <bit-label>{{ "type" | i18n }}</bit-label>
          <bit-select
            [items]="usernameOptions$ | async"
            formControlName="nav"
            data-testid="username-type"
          >
          </bit-select>
          <bit-hint *ngIf="!!(credentialTypeHint$ | async)">{{
            credentialTypeHint$ | async
          }}</bit-hint>
        </bit-form-field>
      </form>
      <form *ngIf="showForwarder$ | async" [formGroup]="forwarder" class="tw-container">
        <bit-form-field>
          <bit-label>{{ "service" | i18n }}</bit-label>
          <bit-select
            [items]="forwarderOptions$ | async"
            formControlName="nav"
            data-testid="email-forwarding-service"
          >
          </bit-select>
        </bit-form-field>
      </form>
      <tools-catchall-settings
        *ngIf="(showAlgorithm$ | async)?.id === 'catchall'"
        [userId]="userId$ | async"
        (onUpdated)="generate('catchall settings')"
      />
      <tools-forwarder-settings
        *ngIf="!!(forwarderId$ | async)"
        [forwarder]="forwarderId$ | async"
        [userId]="this.userId$ | async"
      />
      <tools-subaddress-settings
        *ngIf="(showAlgorithm$ | async)?.id === 'subaddress'"
        [userId]="userId$ | async"
        (onUpdated)="generate('subaddress settings')"
      />
      <tools-username-settings
        *ngIf="(showAlgorithm$ | async)?.id === 'username'"
        [userId]="userId$ | async"
        (onUpdated)="generate('username settings')"
      />
    </bit-card>
  </div>
</bit-section>
