<form [formGroup]="settings" class="tw-container">
  <bit-form-control>
    <input
      bitCheckbox
      formControlName="wordCapitalize"
      type="checkbox"
      (change)="save('wordCapitalize')"
    />
    <bit-label>{{ "capitalize" | i18n }}</bit-label>
  </bit-form-control>
  <bit-form-control>
    <input
      bitCheckbox
      formControlName="wordIncludeNumber"
      type="checkbox"
      (change)="save('wordIncludeNumber')"
    />
    <bit-label>{{ "includeNumber" | i18n }}</bit-label>
  </bit-form-control>
</form>
